import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpInterceptorService } from './http-interceptor.service';
import { MenuComponent } from './menu/menu.component';
import { LogoutComponent } from './logout/logout.component';
import { DuediligenceComponent } from './duediligence/duediligence.component';
import { DdmenuComponent } from './duediligence/ddmenu/ddmenu.component';
import { AddprojectComponent } from './duediligence/addproject/addproject.component';
import { ListprojectComponent } from './duediligence/listproject/listproject.component';
import { UsermenuComponent } from './duediligence/usermenu/usermenu.component';
import { DdformComponent } from './duediligence/ddform/ddform.component';
import { DeaformComponent } from './duediligence/deaform/deaform.component';
import { HrformComponent } from './duediligence/hrform/hrform.component';
import { ItformComponent } from './duediligence/itform/itform.component';
import { MarketingformComponent } from './duediligence/marketingform/marketingform.component';
import { EditprojectComponent } from './duediligence/editproject/editproject.component';
import { CensusComponent } from './census/census.component';
import { ConfigComponent } from './census/config/config.component';
import {SessionExpirationAlert} from './session-expiration-alert/session-expiration-alert.module';
import {MsalModule} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MainComponent} from "./main/main.component";
import {DDSettingsComponent} from "./duediligence/ddsettings/ddsettings.component";
import {HrformComponentN} from "./duediligence/hrform/hrform.component-n";
import { HrFormPlanCostInfoComponent } from './duediligence/hrform/plan-cost-info/hr-form-plan-cost-info.component';
import { HrFormNotesComponent } from './duediligence/hrform/notes/hr-form-notes.component';
import { HrFormPayrollInfoComponent } from './duediligence/hrform/hr-form-payroll-info/hr-form-payroll-info.component';
import { HrFormGeneralInfoComponent } from './duediligence/hrform/hr-form-general-info/hr-form-general-info.component';
import { HrFormPlanInfoComponent } from './duediligence/hrform/hr-form-plan-info/hr-form-plan-info.component';
import { HrFormRetirementPlanInfoComponent } from './duediligence/hrform/hr-form-retirement-plan-info/hr-form-retirement-plan-info.component';
import { HrFormLeaveInfoComponent } from './duediligence/hrform/hr-form-leave-info/hr-form-leave-info.component';
import { HrFormCompPtoInfoComponent } from './duediligence/hrform/hr-form-comp-pto-info/hr-form-comp-pto-info.component';
import { HrFormEmployeeInfoComponent } from './duediligence/hrform/hr-form-employee-info/hr-form-employee-info.component';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    MenuComponent,
    LogoutComponent,
    DuediligenceComponent,
    DDSettingsComponent,
    DdmenuComponent,
    AddprojectComponent,
    ListprojectComponent,
    UsermenuComponent,
    DdformComponent,
    DeaformComponent,
    HrformComponent,
    HrformComponentN,
    ItformComponent,
    MarketingformComponent,
    EditprojectComponent,
    CensusComponent,
    ConfigComponent,
    HrFormPlanCostInfoComponent,
    HrFormNotesComponent,
    HrFormPayrollInfoComponent,
    HrFormGeneralInfoComponent,
    HrFormPlanInfoComponent,
    HrFormRetirementPlanInfoComponent,
    HrFormLeaveInfoComponent,
    HrFormCompPtoInfoComponent,
    HrFormEmployeeInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SessionExpirationAlert.forRoot({totalMinutes: 30}),
    MsalModule.forRoot(new PublicClientApplication({
      auth: environment.auth,
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ])
    }),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
